import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { serializers } from '@lib/serializers'
import Link from 'next/link'

import Photo from '@components/photo'
import { canonicalUrl } from '@components/link'

const RetailersList = ({ data = {} }) => {
  const { retailers } = data
  if (!retailers.length) return null

  return data.featuredOnly ? (
    <section className="featured-retailers">
      <div className="featured-retailers--list">
        {retailers
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((r) => r.featured)
          .map((retailer, i) => (
            <div className="featured-retailers--retailer" key={i}>
              <Link href={canonicalUrl('/stockist')} passHref>
                <a>
                  <Photo
                    photo={retailer.logo}
                    layout="intrinsic"
                    srcsizes={[300]}
                  />
                </a>
              </Link>
            </div>
          ))}
      </div>
    </section>
  ) : (
    <section className="retailers-list">
      <div className="retailers-list--list">
        {retailers
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((retailer, i) => (
            <div className="retailers-list--retailer" key={i}>
              <h5 className="retailers-list--name">
                <a href={retailer.website} target="_blank">
                  {retailer.name}
                </a>
              </h5>
              <BlockContent
                className="retailers-list--address"
                renderContainerOnSingleChild
                blocks={retailer.address}
                serializers={serializers}
              />
              <p className="retailers-list--website">
                <a href={retailer.website} target="_blank">
                  {retailer.website}
                </a>
              </p>
            </div>
          ))}
      </div>
    </section>
  )
}

export default RetailersList
